
    import axios from "axios";
    import { crud_empresa, tabela_create, crud_sql, get_dados_usuario } from './DSi_sql'
    import { return_key } from './DSi_rotinas'

    var url =  sessionStorage['url']

    export async function  crud_sql_canal (sql) {

        const res = await axios.post(url+'clientes/canal.php', {
            acao:   6,
            meuSQL: sql
        })
      
        // console.log(res.data);
        return res.data
    };

    export async function  crud_abrir_tabela_canal (sql) {

        const res = await axios.post(url+'clientes/canal.php', {
            acao:   5,
            meuSQL: sql
        })
      
        // console.log(res.data);
        return res.data
    };


    // passo o ide para selecionar o cliente
    export async function  crud_abrir_tabela_ponte (ide, sql) {

        const res = await axios.post(url+'clientes/ponte.php', {
            acao:   5,
            ide :  ide,
            meuSQL: sql
        })
      
        // console.log(res.data);
        return res.data
    };


    // passo o ide para selecionar o cliente
    export async function  crud_sql_ponte (ide, sql) {

        const res = await axios.post(url+'clientes/ponte.php', {
            acao:   6,
            ide :  ide,
            meuSQL: sql
        })
      
        // console.log(res.data);
        return res.data
    };





    export async function crud_busca_user(db, cpf, senha){


        if ((!senha)||((senha==' ')||(senha == '   '))){
             senha = 'naodigitousenha!!!!!' 
        }
    

        // altera senha quando loga com a senha de recuperacao
        
        let sql = `UPDATE usuarios set SENHA_RECUPERACAO = null where SENHA_RECUPERACAO = ' ';`
        // console.log(sql);
        await crud_sql_canal(sql) 


        sql = `
            SELECT 
              usuarios.CODIGO_CLIENTE as EMPRESA_CODIGO,
              clientes.NOME as EMPRESA_NOME,
              clientes.SITUACAO,
              clientes.BLOQUEAR,
              usuarios.CODIGO,
              usuarios.NOME as USUARIO,
              usuarios.ATIVO,
              usuarios.EMAIL,
              usuarios.LINK,
              usuarios.SUPER,
              usuarios.CADASTRO_U,
              usuarios.FINANCEIRO_R,
              usuarios.FINANCEIRO_U,
              usuarios.TABELAS_R,
              usuarios.TABELAS_U,
              
              usuarios.PARC_GERAR,
              usuarios.PARC_ALTERA,
              usuarios.PARC_ESTORNA,
              usuarios.PARC_EXCLUI,

              usuarios.SENHA_RECUPERACAO
              
            FROM
              usuarios
              INNER JOIN clientes ON (usuarios.CODIGO_CLIENTE=clientes.CODIGO) 
            WHERE
                (clientes.SITUACAO IN ('ATIVO','TESTE'))and (usuarios.ATIVO = 'S') and
                (usuarios.CPF = '${cpf}') and 
                ((usuarios.SENHA = '${senha}') or (usuarios.SENHA_RECUPERACAO = '${senha}'));
            `


        //   console.log(sql);
        //   return 'exit'
          const res = await axios.post(url+'clientes/canal.php', {
            acao:       5,
            meuSQL:     sql,
          })

          console.log('crud_busca_user:', res);
          return res
  

    };




    function define_data_hora(){
        

        let retorno = {}
        //data longa 20 de abrthatil de 2022
        let b = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short',hour12: false, timeZone: 'America/Sao_Paulo' ,  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}); 

        retorno.hoje_week = b;
        

        // console.log('update_values');
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        // console.log('hoje = '+ a);

        retorno.hoje = a

        // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
        a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
        // console.log('hora = '+ a);
        retorno.hora = a;

        a = retorno.hoje + '_'+ retorno.hora;
        // console.log('data_hora = '+ a);
        retorno.data_hora = a;


        a = retorno.hoje.slice(6, 10) +'-'+ retorno.hoje.slice(3, 5) +'-'+ retorno.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        retorno.today = a;

        a = retorno.data_hora+' ' /* +this.user */;
        // console.log('alterou = '+ a);
        retorno.quem_alterou = a;


        // console.log(retorno);

        return retorno
    };




    export  function randomString(length){
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
        let result = ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
    
        return result;
      };


      export async function enviar_email_recuperacao(para){


        // TRATA A STRING REMOVE CAMPOS E CARACTERES
        para = para.trim()
        para = para.replaceAll("'",'´');//sql injection
          
        let sql =  `select * from usuarios where email = '${para}'`

        let retorno = {}

        // console.log(sql);
        const res = await axios.post(url+'clientes/canal.php', {
            acao  : 5,
            meuSQL: sql,
        })

        console.log('enviar_email_recuperacao:', res.data);


        return retorno =  await executa(this, para, res.data)

        async function executa(that, para, users){

            // ALTERO A SENHA DE RECUPERAÇÃO E MANDO A NOVA SENHA PARA O EMAIL
            let nova_senha  =  randomString(5)
            nova_senha      =  nova_senha.trim()
            nova_senha      = nova_senha.toLocaleLowerCase() //deixo minuscula para facilitar ditiacao no celular..
          
          if (users?.length>0){

                for(let i = 0; i <= users.length-1; i++){

                    let sql = `UPDATE USUARIOS SET USUARIOS.SENHA_RECUPERACAO = '${nova_senha}' WHERE USUARIOS.CODIGO =${users[i]?.CODIGO}`
                    // console.log(sql);
                    await crud_sql_canal(sql) 
                }



                let texto =   `<a href="https://sistemasdsi.com.br"> <img src="https://dsi.app.br/logos/nova_dsi2.png" height="130" alt="Sistemas DSi."> </a> <br>`
                texto     +=  `<br/> Olá <strong style="color:blue">${users[0].NOME}</strong>, conforme solicitação, segue sua senha de recuperação: <br/><br/>`
                texto     +=  `Acesse: <strong style="color:blue">dsi.app.br</strong>`
                texto     +=  `<br/>Senha de Recuperação: <strong style="color:black">${nova_senha}</strong>`
                texto     +=  `<br/><br/><h2>Sistemas DSi</h2>`

                let r = await enviar_email(para,'Recuperação de Senha', texto)
                retorno = { texto : 'Senha de Recuperação enviada para seu e-mail', cor:'green'}

            }
            else{
                retorno = {texto:'E-mail não cadastrado!', cor:'orange lighten-0'}
            }

            return retorno
        }


      };


      export async function enviar_email(para, assunto, texto){
        console.log('enviar_email:');

        var url =  sessionStorage['url']
        let link = url+"email.php"

        const data = {
                  
                  para:      para,
                  assunto:   assunto, 
                  texto:     texto,
        }  

        // console.log(data);
        const header =   { headers: {'Content-Type': 'multipart/form-data'}}

        const res = await axios.post(link, data, header);
        console.log(res.data);
        return res.data

    }; 


    export async function  dsi_user_online(codigo,status){

        let retorno = define_data_hora()
      
        let sql = `UPDATE USUARIOS SET 
                     USUARIOS.ONLINE = '${status}',
                     ONLINE_HORA = '${retorno.data_hora}'
                   WHERE USUARIOS.CODIGO =${codigo}`

        // console.log(sql);

        // defino online ou offiline la no DSI... ISSO AQUI TEM QUE SER USADO COM MUITO CUIDADO...
        await crud_sql_canal(sql) 

    };


    export async function  registra_log(tipo,log, codigo_aluno){

        // console.log('registra_log:');

        let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  

        let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd

        let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
        let hora_curta = hora.slice(0, 5)

        let obj_user = await get_dados_usuario()
        // console.log('registra_log obj_user.CODIGO:', obj_user.CODIGO);

        // let user = sessionStorage['lg:USUARIO']
        let user = obj_user.USUARIO
        user = user.trim()

        let items =   {
            ATIVO : 'S',
            TIPO : tipo,
            DATA  : today,
            HORAS : hora,
            // CODIGO_OPERADOR : sessionStorage['lg:CODIGO'],
            CODIGO_OPERADOR : obj_user.CODIGO,
            CODIGO_ALUNO  : (codigo_aluno)|| 0,
            OPERADOR : obj_user.USUARIO,
            DESCRICAO : log
        }


        let souDSi = false
        let souDSistring = sessionStorage['souDSi']
        if (souDSistring == 'S'){
            souDSi = true
        }
        

        if (!souDSi) {

            if (user !== 'A'){
               await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
            }
        }
        else{
            await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
        }

        // alert(souDSi)
    
        // if (url == '/api/'){//localhost
        //     await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
        // }
        // else{//producao
        //     if (!souDSi) {
        //         if (user !== 'A'){
        //            await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
        //         }
        //     }
        //     else{
        //         await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
        //     }
        // }

        


    };

    export async function atualiza_tabelas(){
        console.log('atualiza_tabelas');
         
         const res = await axios.get(url+'atu_table.php', {})
        //  let result =  res.data;
         // alert(result)
         console.log(res.data)
    };

    
    export async function backup_database(){
        console.log('backup_database url:', url);
         
         const res = await axios.post(url+'backup.php')
        //  let result =  res.data;
         // alert(result)
         console.log(res.data)
    };


    export async function updateVersoes(versaoAtual){

        // console.log('updateVersoes');
        versaoAtual = sessionStorage['versao']
        // alert(versaoAtual)


        // let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        // a = that.a.slice(6, 10) +'-'+ that.a.slice(3, 5) +'-'+ that.a.slice(0, 2) ; //formato us yyyy-mm-dd
        // let today = a;

        let sql = []
        let r = ''
        let v = versaoAtual
        v = somenteNumeros(v)
        // alert(v)



        // MANUTENÇÃO NO PROPRIO BD
        sql.push( `UPDATE alunos SET WHATS_NIVER = NULL WHERE (WHATS_NIVER) <> YEAR(CURDATE())`)
        // sql.push( `DELETE FROM alerta WHERE data <= '${hoje_mais(-720)}'`)//720 = 2 anos



        //  ATUALIZAR VERSAO VERSOES
        if (v <= 2140){

            // sql.push( `UPDATE whats_respostas AS r
            // JOIN whats_menus AS m ON m.CODIGO = r.RESPOSTA
            // SET r.TEXTO_AUSENTE = m.ID
            // WHERE r.TIPO = 'MENU'`)//2113

            // sql.push( `UPDATE whats_respostas set RESPOSTA = TEXTO_AUSENTE WHERE whats_respostas.TIPO = 'MENU'`)//2113
            // sql.push( `UPDATE whats_respostas set ID_MENU = (select ID from whats_menus where CODIGO = whats_respostas.COD_MENU)`)//2113

            sql.push( `ALTER TABLE tabela_fixas ADD COLUMN CODIGO_ALUNO int(9)`)//2125
            sql.push( `ALTER TABLE tabela_fixas ADD COLUMN CODIGO_SERVICO int(9)`)//2125

            sql.push( `ALTER TABLE conta ADD COLUMN WHATS_LEMBRETE varchar(10)`)//2119
            sql.push( `ALTER TABLE conta ADD COLUMN WHATS_ATRASADO varchar(10)`)//2119
            sql.push( `ALTER TABLE conta ADD COLUMN CODIGO_USUARIO int(11)`)//2119

            sql.push( `ALTER TABLE conta DROP COLUMN CODIGO_DESK`)//2110//remove a coluna CODIGO_DESK esta dando erro ao receber parcial

            sql.push( `ALTER TABLE empresa ADD COLUMN C_DIAS_BLOQUEIA int(11)`)//2110

            sql.push( `ALTER TABLE clientes ADD COLUMN SERIALIZED varchar(100)`)//2109

            sql.push( `ALTER TABLE whats_interacao ADD COLUMN VISTO varchar(1)`)//2108

            sql.push( `ALTER TABLE alunos ADD QUEM_CADASTROU varchar(200)`)//2106

            sql.push( `ALTER TABLE clientes ADD API_WHATS_CONECTADO varchar(20)`)//2102

            // sql.push( `ALTER TABLE whats_respostas ADD HORA_DAS varchar(20)`)//2101
            // sql.push( `ALTER TABLE whats_respostas ADD HORA_ATE varchar(20)`)//2101
            // sql.push( `ALTER TABLE whats_respostas ADD TEXTO_AUSENTE varchar(500)`)//2101

            // sql.push( `ALTER TABLE whats_respostas ADD COLUMN INTERVALO int(9)`)//2100
            // sql.push( `ALTER TABLE whats_respostas ADD COLUMN INTERVALO_TIPO varchar(20)`)//2100
            // sql.push( `ALTER TABLE whats_interacao ADD COLUMN URL_FILE varchar(9999)`)//2100

            // sql.push( `ALTER TABLE assinaturas ADD COLUMN ID_USER int(9)`)//2099
            // sql.push( `update assinaturas set ID_USER = 0 where ID_USER is null`)

            // sql.push( `ALTER TABLE servicos ADD COLUMN DATA_EXPIRA date`)//2095
            // sql.push( `ALTER TABLE servicos ADD COLUMN ALERTA_EXPIRA varchar(1)`)//2095

            // sql.push( `ALTER TABLE registro_log ADD COLUMN CODIGO_ALUNO integer`)//2095
            // sql.push( `ALTER TABLE alunos ADD COLUMN WHATS_NIVER varchar(20)`)//2095
            
            // sql.push( `ALTER TABLE registro_log CHANGE COLUMN DESCRICAO DESCRICAO VARCHAR(500)`)//2094

            // sql.push( `ALTER TABLE alunos CHANGE COLUMN OBSERVACAO OBSERVACAO VARCHAR(9999)`)//2092
            // sql.push( `ALTER TABLE conta CHANGE COLUMN OBSERVACAO OBSERVACAO VARCHAR(9999)`)//2092

            // sql.push( `ALTER TABLE tabela_api_whats ADD COLUMN URL_FILE varchar(9999)`)//2091
            // sql.push( `ALTER TABLE alerta ADD COLUMN ENVIAR_ZAP varchar(20)`)//2091


            // sql.push( `ALTER TABLE assinaturas ADD COLUMN ANO_REAJUSTOU varchar(20)`)//2089
            // sql.push( `ALTER TABLE assinaturas ADD COLUMN double_VALOR_ANTIGO double(10,2)`)//2089

            // sql.push( `ALTER TABLE clientes ADD COLUMN DATA_TESTE_ACABA date`)//2086

            // sql.push( `ALTER TABLE alunos ADD COLUMN FOTO_URL varchar(300)`)//2086
            // sql.push( `ALTER TABLE clientes ADD COLUMN FOTO_URL varchar(300)`)//2086

            // sql.push( `ALTER TABLE assinaturas ADD COLUMN DATA_REAJUSTAR date`)//2086

            // sql.push( `ALTER TABLE usuarios ADD COLUMN CELULAR VARCHAR(20)`)//2085

            // sql.push( `ALTER TABLE alerta ADD COLUMN VISTO VARCHAR(1)`)//2084
            // sql.push( `ALTER TABLE alerta ADD COLUMN TIPO VARCHAR(20)`)//2084

            // sql.push( `ALTER TABLE assinaturas ADD COLUMN REAJUSTE VARCHAR(20)`)//2083

            // sql.push( `ALTER TABLE tabela_webhooks ADD COLUMN URL_FOTO VARCHAR(500)`)//2080
            // sql.push( `ALTER TABLE tabela_webhooks ADD COLUMN RESPOSTA VARCHAR(500)`)//2080
            // sql.push( `ALTER TABLE tabela_api_whats ADD COLUMN RESPONDEU VARCHAR(1)`)//2080
            // sql.push( `ALTER TABLE tabela_api_whats ADD COLUMN SERIALIZED VARCHAR(100)`)//2080
            // sql.push( `ALTER TABLE assinaturas ADD COLUMN SALVAR_WEBHOOK VARCHAR(1)`)//2080


            // sql.push( `ALTER TABLE tabela_api_whats CHANGE COLUMN WHATS_MSG WHATS_MSG VARCHAR(9999)`)//2078
            // sql.push( `update clientes set token = codigo*777888999 where token is null`)


        }



        

        // console.log(sql);
        // alert(sql.length)

        if (sql?.length>0){
          for(let i = 0; i <= sql.length; i++){

            r = await crud_sql(this, sql[i], 'N'); 
            // console.log(r);
          }
        }


    };



    function somenteNumeros(string){
        var numsStr = string.replace(/[^0-9]/g,'');
        return parseInt(numsStr);
    };



    async function manutencao_servidor(){
        
        // apaga dados apos 1 dia
        let sql = `DELETE FROM tabela_webhooks WHERE data <= '${hoje_mais(-1)}';`                 +
                //   `DELETE FROM tabela_api_whats WHERE data <= '${hoje_mais(-1)}';`                +
                  `DELETE FROM tabela_api_whats WHERE STR_TO_DATE(CONCAT(DATA, ' ', HORA), '%Y-%m-%d %H:%i:%s') <= NOW() - INTERVAL 1 DAY;`+
                  `DELETE FROM whats_interacao  WHERE STR_TO_DATE(CONCAT(VALIDO_DATA, ' ', VALIDO_HORA), '%Y-%m-%d %H:%i:%s') <= NOW();`+

                  `DELETE FROM tabela_webhooks WHERE CONTEUDO LIKE  '%isGroup":true%';`           +
                  `UPDATE clientes set TOKEN = codigo*777888999 where TOKEN is null;`             +
                  `update assinaturas set assinaturas.REAJUSTE = '10' where REAJUSTE is null;`    +
                  
                  `DELETE FROM pix_webhooks
                    WHERE 
                    (DATA < DATE_SUB(NOW(), INTERVAL 2 DAY) AND VISTO = 'S')
                    OR
                    (DATA < DATE_SUB(NOW(), INTERVAL 4 DAY) AND VISTO = 'N');
                  ;`    +

                  `UPDATE assinaturas SET DATA_REAJUSTAR = CONCAT(YEAR(CURDATE()) + 1, '-01-01') WHERE DATA_REAJUSTAR IS NULL;` 

                  

        // console.log('hoje_mais:', hoje_mais(-720));

        localStorage['SQL.manutencao_servidor:', sql]
        
        // console.log('manutencao_servidor.sql:', sql);
        await crud_sql_canal(sql) 
    };

    function hoje_mais(dias){

        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
        let hoje = a

        let b = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        let today = b;

        let c = new Date(today+' 00:00:00')//sempre que criar data definir horario
        c.setDate(c.getDate() + dias)//adiciono dias na data
        let r = new Date(c).toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy 
        let ontem = r.slice(6, 10) +'-'+ r.slice(3, 5) +'-'+ r.slice(0, 2) ; //formato us yyyy-mm-dd

        return ontem
    };


    // PRINCIPAL... É CHAMADO QUANDO SELECIONA O USUARIO E VAI ENTRAR (TELA LOGIN)
    export async function ver_permissoes_user(that, dados_usuario){
        
        that.overlay  = true

        // if (dados_usuario.BLOQUEAR == 'S'){
        // //  esta com parcela atrasada.. redireciona para o link de pagamento
        //     that?.alerta('Acesso Pendente no momento!','orange')
            
        //     that.overlay  = false 
        //     that.password_dsi = ''//limpa o campo senha
            
        //     return false
        // }
        // else{

        // }

        let isMobile = window.innerWidth < 600


        //EXPIRA EM 3 HORAS //ver se expirou o login
        var expirar  = new Date();
        expirar.setHours(expirar.getHours() + 3);
        // expirar.setMinutes(expirar.getMinutes() + 1);//testar com minutos
    
        //salvo no sessionStorage objeto em formato string
        sessionStorage['obj_user']  =  JSON.stringify(dados_usuario)
    

        //PERMISSOES:
        sessionStorage['buffer']          = 'S'//logado
        sessionStorage['refresh_size']    =  return_key()//logado
        sessionStorage['expira']          =  expirar 
        // sessionStorage['refresh_size']    = that.return_key()//logado
        //pego o mes e o dia multiplico e chego num valor.. sera analisado   

        // sessionStorage['84hfjdyruxy1_is'] =  DSirotinas.cripto(res.data[0].USUARIO)

        let superUser                      =  dados_usuario.SUPER
        sessionStorage['lg:S']             =  dados_usuario.SUPER 
        sessionStorage['lg:USUARIO']       =  dados_usuario.USUARIO
        sessionStorage['superA']           = (dados_usuario.USUARIO == 'A')
        sessionStorage['lg:CODIGO']        =  dados_usuario.CODIGO //codigo do operador

        sessionStorage['lg:EMPRESA']       =  dados_usuario.EMPRESA_NOME
        sessionStorage['lg:IDE']           =  dados_usuario.EMPRESA_CODIGO
        sessionStorage['lg:SIZE']          =  dados_usuario.EMPRESA_CODIGO * 777
        // sessionStorage['lg:BBB']           =  'cfc_'+dados_usuario.EMPRESA_CODIGO
        
        sessionStorage['lg:CADASTRO_U']    =  dados_usuario.CADASTRO_U
        sessionStorage['lg:FINANCEIRO_R']  =  dados_usuario.FINANCEIRO_R
        sessionStorage['lg:FINANCEIRO_U']  =  dados_usuario.FINANCEIRO_U
        sessionStorage['lg:TABELAS_R']     =  dados_usuario.TABELAS_R
        sessionStorage['lg:TABELAS_U']     =  dados_usuario.TABELAS_U



        
        if (dados_usuario.EMPRESA_CODIGO == '45') {
            sessionStorage['souDSi'] = 'S'
        }else{
            sessionStorage['souDSi'] = 'N'
        }


        //------------- EXECUTA ALGUMAS FUNCOES -----------------

        
        //define logado ou nao no DSI 45
        await dsi_user_online(dados_usuario.CODIGO, 'S')



        // REGISTRO NO LOG LOCAL
        await registra_log('ENTROU', (isMobile)? 'Entrou via Celular' : 'Entrou')


        // ATUALIZA AS TABELAS DO BANDO DE DADOS.. NOVA COLUNAS, NOVAS TABELAS...ETC
        await atualiza_tabelas()


        // VERIFICA A VERSAO E ATUALIZA TABELAS POR VERSOES
        await updateVersoes()


        // ABRO OS DADOS DA EMPRESA E SALVO NA SESSION
        let r = null
        if ( r = await crud_empresa()){
            // console.log('empresa local:', r);
            // caso seja cliente novo..busca os dados da empresa la no dsi45
            r = r[0]
            if (r.CNPJ == null){
                // console.log('buscar dados do dsi do ide:', dados_usuario.EMPRESA_CODIGO);

                let sql = `select * from clientes where codigo = ${dados_usuario.EMPRESA_CODIGO} `
                let empresa_45 = null
                if (empresa_45 = await crud_abrir_tabela_canal(sql)){
                    // console.log('buscou:', empresa_45);

                    empresa_45 = empresa_45[0]

                    sql = `update empresa set
                           NOME         = '${empresa_45.NOME}', 
                           RAZAO_SOCIAL = '${empresa_45.NOME}', 
                           CNPJ         = '${empresa_45.CNPJ_CPF}', 
                           FONE1        = '${empresa_45.FONE}', 
                           CELULAR      = '${empresa_45.CELULAR}', 
                           EMAIL        = '${empresa_45.EMAIL}', 
                           CEP          = '${empresa_45.CEP}', 
                           ENDERECO     = '${empresa_45.ENDERECO}', 
                           BAIRRO       = '${empresa_45.BAIRRO}', 
                           CIDADE       = '${empresa_45.CIDADE}', 
                           UF           = '${empresa_45.UF}' 
                    `

                    if(await crud_sql(this, sql,'N')){
                        // apos atualizado..busca os dados locais novamente para salvar nas sessions
                        await crud_empresa()
                        
                    }

                }
            }
        }



        // executa algumas funcoes somente se for DSI 45
        // if (dados_usuario.EMPRESA_CODIGO == '45') {
            await manutencao_servidor()
        // }


        // await backup_database() //estou fazendo o backup quando da o oncreate da janela Cadastro.vue
        // await abre_pagina(this)

        // async function abre_pagina(that){
        //   that.$router.push('/cadastro')//redireciono para a pagina do router
        // }

        that.overlay = false

        return true


    };


    export async function faz_backup(){
       // EXECUTA O BACKUP DO BANCO DE DADOS

       let ide         = sessionStorage['lg:IDE'] 
       let agora       = new Date();

       let next_backup = ''

       if (localStorage['next_backup_'+ide]){
          next_backup   =  new Date(localStorage['next_backup_'+ide])
       }
       else{
        next_backup = agora  
       }


       if (next_backup <= agora){

         console.log('INICIANDO BACKUP...');
         
         await backup_database()

         agora.setHours(agora.getHours() + 5);//proximo backup somente daqui 5 horas

         localStorage['next_backup_'+ide] = agora

         console.log('TERMINANDO O BACKUP...');

       }

       
    };


